<template>
  <a-form
    ref="formRef"
    :model="formState"
    :rules="rules"
    label-align="left"
    layout="vertical"
    @finishFailed="handleFinishFailed"
  >
    <br />
    <a-form-item name="id" label="ID">
      <a-input v-model:value="formState.id" size="large" />
    </a-form-item>
    <a-tabs v-model:activeKey="activeKey" default-active-key="1" size="large">
      <a-tab-pane key="1" tab="Titre">
        <a-form-item name="titleFr">
          <a-input v-model:value="formState.titleFr" placeholder="Titre" />
        </a-form-item>
      </a-tab-pane>
      <a-tab-pane key="2" tab="عنوان">
        <a-form-item name="titleAr">
          <a-input v-model:value="formState.titleAr" placeholder="عنوان" />
        </a-form-item>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Title">
        <a-form-item name="titleEn">
          <a-input v-model:value="formState.titleEn" placeholder="Title" />
        </a-form-item>
      </a-tab-pane>
    </a-tabs>
    <a-form-item label="Categorie" name="category">
      <a-select v-model:value="formState.category">
        <a-select-option v-for="category of categories" :key="category._id" :value="category._id">
          {{ category.title[language[activeKey ? parseInt(activeKey) - 1 : 0]] }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-tabs v-model:activeKey="activeKey" default-active-key="1" size="large">
      <a-tab-pane key="1" tab="Contenue ">
        <a-form-item name="descFr">
          <quill-editor v-model:value="formState.descFr" style="height: 200px"> </quill-editor>
        </a-form-item>
      </a-tab-pane>
      <a-tab-pane key="2" tab="محتوى">
        <a-form-item name="descAr">
          <quill-editor v-model:value="formState.descAr" style="height: 200px"> </quill-editor>
        </a-form-item>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Content">
        <a-form-item name="descEn">
          <quill-editor v-model:value="formState.descEn" style="height: 200px"> </quill-editor>
        </a-form-item>
      </a-tab-pane>
    </a-tabs>
    <!-- couverture -->
    <a-tabs v-model:activeKey="activeKey" default-active-key="1" size="large">
      <a-tab-pane key="1" tab="Photo de couverture">
        <!-- french -->

        <a-form-item label="Photo de couverture">
          <a-upload-dragger
            :multiple="false"
            :file-list="covImgFileListFr"
            :before-upload="beforeUpload"
            :custom-request="
              () => {
                return true
              }
            "
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">
              Cliquez ou faites glisser le fichier vers la zone à télécharger
            </p>
            <p class="ant-upload-hint">
              Prise en charge d'un téléchargement unique ou groupé.
            </p>
          </a-upload-dragger>
        </a-form-item>
      </a-tab-pane>
      <!-- arabic -->

      <a-tab-pane key="2" tab="صورة الغلاف">
        <a-form-item label="صورة الغلاف">
          <a-upload-dragger
            :multiple="false"
            :file-list="covImgFileListAr"
            :before-upload="beforeUpload"
            :custom-request="
              () => {
                return true
              }
            "
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">انقر أو اسحب الملف إلى المنطقة لتحميله</p>
            <p class="ant-upload-hint">
              دعم للتحميل الفردي أو المجمع.
            </p>
          </a-upload-dragger>
        </a-form-item>
      </a-tab-pane>
      <!-- english -->

      <a-tab-pane key="3" tab="Cover photo">
        <a-form-item label="Cover photo">
          <a-upload-dragger
            :multiple="false"
            :file-list="covImgFileListEn"
            :before-upload="beforeUpload"
            :custom-request="
              () => {
                return true
              }
            "
          >
            <p class="ant-upload-drag-icon">
              <inbox-outlined></inbox-outlined>
            </p>
            <p class="ant-upload-text">Click or drag file to area to upload</p>
            <p class="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or
              other band files
            </p>
          </a-upload-dragger>
        </a-form-item>
      </a-tab-pane>
    </a-tabs>
    <a-form-item label="Attaché des fichiers">
      <a-upload-dragger
        :file-list="files"
        :before-upload="beforeUploadFiles"
        :custom-request="
          () => {
            return true
          }
        "
      >
        <p class="ant-upload-drag-icon">
          <inbox-outlined></inbox-outlined>
        </p>
        <p class="ant-upload-text">Click or drag file to area to upload</p>
        <p class="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or
          other band files
        </p>
      </a-upload-dragger>
    </a-form-item>
    Envoyer aux abonnés
    <a-switch v-model:checked="sendToSubscribed" checked-children="oui" un-checked-children="non" />
    <br /><br />
    <a-form-item>
      <button class="btn btn-success btn-with-addon text-nowrap" @click="onSubmit">
        <span class="btn-addon">
          <i class="btn-addon-icon fe fe-plus-circle"></i>
        </span>
        Ajouter publication
      </button>
    </a-form-item>
  </a-form>
</template>
<script>
/* eslint-disable */
import { quillEditor } from 'vue3-quill'
import { InboxOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { defineComponent, toRaw, ref, reactive, toRefs } from 'vue'
import { message } from 'ant-design-vue'

import ApiClient from '@/services/axios'

export default defineComponent({
  components: {
    InboxOutlined,
    quillEditor,
  },
  props: {
    categories: {
      type: Array,
      default: [],
    },
  },
  setup(props) {
    //defining computed vars
    const { categories } = toRefs(props)
    //defining consts
    const rules = {
      titleFr: [
        {
          required: true,
          message: 'Le titre est obligatoire, au moins en français',
          trigger: 'blur',
          validator: (rule, value, callback) => {
            if (value || formState.titleAr || formState.titleEn) {
              callback()
            } else {
              callback(new Error('Un des titres est obligatoire (Français, Arabe ou Anglais)'))
            }
          },
        },
      ],
      titleAr: [
        {
          required: true,
          message: 'Le titre est obligatoire, au moins en arabe',
          trigger: 'blur',
          validator: (rule, value, callback) => {
            if (value || formState.titleFr || formState.titleEn) {
              callback()
            } else {
              callback(new Error('Un des titres est obligatoire (Français, Arabe ou Anglais)'))
            }
          },
        },
      ],
      titleEn: [
        {
          required: true,
          message: 'Le titre est obligatoire, au moins en anglais',
          trigger: 'blur',
          validator: (rule, value, callback) => {
            if (value || formState.titleFr || formState.titleAr) {
              callback()
            } else {
              callback(new Error('Un des titres est obligatoire (Français, Arabe ou Anglais)'))
            }
          },
        },
      ],
      category: [
        {
          required: true,
          message: 'La catégorie est obligatoire',
          trigger: 'blur',
        },
      ],
    }
    const language = ['fr', 'ar', 'en']
    const router = useRouter()
    // defining data  vars
    const formState = reactive({
      titleFr: '',
      category: '',
      titleAr: '',
      titleEn: '',
      descFr: '',
      descAr: '',
      descEn: '',
      id: '',
    })
    const sendToSubscribed = ref(false)
    const formRef = ref()
    const activeKey = ref('1')
    const files = ref([])
    const fileList = ref([])
    const covImgFileListFr = ref([])
    const covImgFileListAr = ref([])
    const covImgFileListEn = ref([])

    //  defining functions
    const deletetEmpty = obj => {
      for (const key of Object.keys(obj)) if (obj[key] == '') delete obj[key]
      return obj
    }
    const sendToSubscribedCallBack = () => {
      console.log('send to subscribed')
    }
    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          let tempData = toRaw(formState)
          let data = {
            title: {
              fr: tempData.titleFr,
              en: tempData.titleEn,
              ar: tempData.titleAr,
            },
            desc: {
              fr: tempData.descFr,
              en: tempData.descEn,
              ar: tempData.descAr,
            },
            category: tempData.category,
            id: tempData.id,
          }
          data.title = deletetEmpty(data.title)
          data.desc = deletetEmpty(data.desc)
          data.id = deletetEmpty(data.id)
          ApiClient.put('/publications', {
            data,
          })
            .then(res => {
              let formData = new FormData()
              let imageChanged = 0
              if (covImgFileListFr.value[0]) {
                formData.append('coverImgFr', covImgFileListFr.value[0], res.data._id)
                imageChanged += 1
              }
              if (covImgFileListAr.value[0]) {
                formData.append('coverImgAr', covImgFileListAr.value[0], res.data._id)
                imageChanged += 1
              }
              if (covImgFileListEn.value[0]) {
                formData.append('coverImgEn', covImgFileListEn.value[0], res.data._id)
                imageChanged += 1
              }
              if (files.value.length > 0) {
                for (const file of files.value) formData.append('files[]', file, 'file')
                imageChanged += 1
              }
              if (imageChanged) {
                formData.append('sendToSubscribed', sendToSubscribed.value)
                ApiClient.patch('/publications/files/' + res.data._id, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                }).catch(function(e) {
                  console.error(e)

                  message.error("Impossible de télécharger l'image du coverture: ")
                })
              }
            })
            .catch(e => {
              console.error(e)
              message.error("Impossible d'ajouter la publication")
            })
            .finally(function() {
              message.success('Publication Ajouter')
              router.push('/publications')
            })
        })
        .catch(e => console.debug(e))
    }

    const beforeUpload = (file, _) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('Vous ne pouvez télécharger que des fichiers JPG, JPEG ou PNG!')
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        message.error("L'image doit être inférieure à 5 Mo!")
      }
      if (isJpgOrPng && isLt2M) {
        message.success(`${file.name} fichier téléchargé avec succès..`)

        if (activeKey.value == 1) {
          covImgFileListFr.value = [file]
        } else if (activeKey.value == 2) {
          covImgFileListAr.value = [file]
        } else if (activeKey.value == 3) {
          covImgFileListEn.value = [file]
        }
      }
      return isJpgOrPng && isLt2M
    }

    const beforeUploadFiles = (file, _) => {
      files.value.push(file)
      return true
    }
    const handleFinishFailed = errors => {
      console.log(errors)
    }
    return {
      handleFinishFailed,
      sendToSubscribed,
      activeKey,
      language,
      files,
      fileList,
      covImgFileListFr,
      covImgFileListAr,
      covImgFileListEn,

      formState,
      categories,
      rules,
      formRef,
      onSubmit,
      beforeUpload,
      beforeUploadFiles,
    }
  },
})
</script>
